<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component 
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import OnAdjustment from './lists/OnAdjustment.vue';
import OnCheck from './lists/OnCheck.vue';
import OnExecution from './lists/OnExecution.vue';
import OnInspect from './lists/OnInspect.vue';
import OnSign from './lists/OnSign.vue';
import OnRework from './lists/OnRework.vue';
import OnControl from './lists/OnControl.vue';
import OnInspectResolution from './lists/OnInspectResolution.vue';

export default {
    name: "InWorkProxy",
    components: {
        Toolbar,
        FilterPanel,
        "Documents.My.OnAdjustment": OnAdjustment,
        "Documents.My.OnCheck": OnCheck,
        "Documents.My.OnExecution": OnExecution,
        "Documents.My.OnInspect": OnInspect,
        "Documents.My.OnSign": OnSign,
        "Documents.My.OnRework": OnRework,
        "Documents.My.OnExecuterControl": OnControl,
        "Documents.My.OnInspect.Resolution": OnInspectResolution
    },
    data () {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null
        }
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['inwork/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('inwork/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['inwork/getStatus'];
            },
            set: function(newValue) {
                this.$store.commit('inwork/SET_STATUS', newValue);

                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['inwork/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {                
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    console.log('search', this.$refs.currentComponent);
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('inwork/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        canSignChief () {
            var user = this.$store.getters['auth/getUserInfo'];
            return user?.permissions.includes("CanSignChief") ?? false;
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData () {
            
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();    

            let response = await httpAPI({
                url: `api/actions/collection?type=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `/api/inwork/filter?canSignChief=${this.canSignChief}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.payload;
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    async created () {
        this.setOverlayVisible({ visible: true });
        try
        {
            await this.loadCollectionData();
        }
        finally
        {
            this.setOverlayVisible({ visible: false });
        }
    }
}
</script>